enum RecommendationSupplementIntakeTimeType {
  During = '1',
  Before = '2',
  After = '3',
}

const { After, Before, During } = RecommendationSupplementIntakeTimeType

const nameMapping = new Map([
  [After, 'После'],
  [Before, 'До'],
  [During, 'Во время']
])

namespace RecommendationSupplementIntakeTimeType {
  export const sortedMembers = [During, Before, After]

  export function getName (recommendationSupplementIntakeTimeType: RecommendationSupplementIntakeTimeType): string {
    return nameMapping.get(recommendationSupplementIntakeTimeType)!
  }
}

export default RecommendationSupplementIntakeTimeType

enum RecommendationSupplementIntakeTime {
  Breakfast = '1',
  Lunch = '2',
  Dinner = '3',
}

const { Breakfast, Dinner, Lunch } = RecommendationSupplementIntakeTime

const nameMapping = new Map([
  [Breakfast, 'Завтрак'],
  [Dinner, 'Ужин'],
  [Lunch, 'Обед']
])

namespace RecommendationSupplementIntakeTime {
  export const sortedMembers = [Breakfast, Lunch, Dinner]

  export function getName (recommendationSupplementIntakeTime: RecommendationSupplementIntakeTime): string {
    return nameMapping.get(recommendationSupplementIntakeTime)!
  }
}

export default RecommendationSupplementIntakeTime
